export const SET_VIEW = `SET_VIEW`;
export const SET_DESTINATION_DATASET = `SET_DESTINATION_DATASET`;
export const SET_DESTINATION_BASEMAP = 'SET_DESTINATION_BASEMAP';
export const SET_ORIGIN_BASEMAP = 'SET_ORIGIN_BASEMAP';
export const SET_DESTINATION_DATAZONE = `SET_DESTINATION_DATAZONE`;
export const SET_TIME_LIMIT = `SET_TIME_LIMIT`;
export const SET_TIME_AT_DESTINATION = `SET_TIME_AT_DESTINATION`;
export const SET_MAP_OPACITY = `SET_MAP_OPACITY`;
export const SET_MAP_COLOR_SCHEME = `SET_MAP_COLOR_SCHEME`;
export const SET_MAP_VIEW_STATE = `SET_MAP_VIEW_STATE`;
export const SET_AB = `SET_AB`;
export const SET_BC = `SET_BC`;
export const SET_LOCATION_INBOUND_DATA = `SET_LOCATION_INBOUND_DATA`;
export const SET_LOCATION_OUTBOUND_DATA = `SET_LOCATION_OUTBOUND_DATA`;
export const SET_SELECTED_DATA_ZONE = `SET_SELECTED_DATA_ZONE`;
export const SET_HOVERED_DATA_ZONE = `SET_HOVERED_DATA_ZONE`;
export const SET_MAP_TOOLTIP = `SET_MAP_TOOLTIP`;
export const SET_SHOW_TRANSIT_NETWORK = `SET_SHOW_TRANSIT_NETWORK`;
export const SET_SHOW_OUTBOUND_HOVER = `SET_SHOW_OUTBOUND_HOVER`;
